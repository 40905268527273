// @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300'); 
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Lilita+One&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Teachers:ital,wght@0,400..800;1,400..800&display=swap');
// Here you can add other styles
#sidebar-logo, #nav-item{ background-color: white; color: #363433;}
#nav-item, #nav-group{ color: black; }
#nav-item:hover{ text-decoration: underline; }
.simplebar-content{ padding: 4px; }
// .nav-item, .nav-group{ margin:10px}
.nav-group-toggle{ border-radius: 2em; border: 2px; }
.sidebar{ border-right: 1px solid #d8dbe0 !important }
#sidebar-logo {padding-bottom:23px; padding-top:14px; border-bottom: 1px solid lightgray;}
.custom-container{ max-width:100% !important }
#nav-item.active {border: 2px rgb(53, 53, 53) solid;border-radius: 10px; background-color: lightgray;}
.nav-icon {color: black}
.sidebar-icons{margin-right: 10px;}
.button-icons{margin-right: 5px; margin-bottom: 3px;}
.modal-header-icons{margin-right: 10px; margin-bottom: 4px;}
.table-font-std {
    
    thead {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    
    tbody td {
        font-size:14px;
    }


   
    tbody {
        display: block;
        max-height: calc(100vh - 465px);
        overflow-y: auto;
        table-layout: fixed;
      }
    
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }    
}
// .table-header-border, .table-hover th{border-bottom: 2px solid;}
.nav-item > #nav-item{padding-left:30px;}
.header-nav-item {font-size: 18pt;color: 'black'; padding-right: '.5em'; font-weight: bold;}
#resourceAllocDiv{overflow-x: auto;}
#skillsMatrixDiv{overflow-x: auto;}

//standards
#resource-button-grp {padding-top:15px;}
#resource-button-grp > .form-switch {color: btn-success;}

//buttons
.button-width-100{
    max-width:100%
}
.button {
    padding: 10px 20px;
    font-size: 16px;
  }

  @media (max-width: 600px) {
    .button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }

.std-button-primary{
    background-color: #0061A2 !important;
}

.std-button-primary:hover{
    background-color: #0b466e !important;
}

//sidebar
ul.nav-group-items{
    margin-top:6px;
}
.nav-group-items > .nav-item {margin-top: 1px; margin-bottom: 1px;}

//header
.header-info-label{text-align:right;}
#headerInfoLine div{
    margin-left:auto;
}

//etc
.accord-width{
    width: 30px;
    margin-right: 10px;
}


//report-filter
.input-group-pre{
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-color: #939393 !important;
    font-size: 12px !important;
    height: 40px !important;
    background-color: #EAEBED !important;
    color: #262628 !important;
    min-width: 120px;
    display: flex;
    justify-content: flex-end;
}

.input-group-post{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    font-size: 12px !important;
    height: 40px !important;
    width: 100%;
    color: #262628;
}

.input-group-holder{
    flex-wrap: nowrap !important;
}

// div#resourceAllocDiv div#skillsMatrixDiv .table-hover th > .d-inline{
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     overflow: hidden;
//     max-width: calc(width-25px) !important;
//     position: absolute;
//     padding-right: 5px;
// }

.table-hover thead{
    border-bottom: solid 2px;
}

div#skillsMatrixDiv .table-hover thead{
    border-bottom: solid 2px;
}

div#resourceAllocDiv .position-relative{
    width: 100%;
}

div#skillsMatrixDiv .position-relative{
    width: 100%;
}

//pagination
ul.pagination > li.page-item:not(.disabled):not(.active){
    cursor: pointer;
}

//GRID WIDTH CLASSES
.cell-min-width-100{
    min-width: 100px !important;

.cell-width-100{
    width: 100px !important;
}
}
.cell-min-width-150{
    min-width: 150px !important;
}

.cell-width-200{
    width: 200px !important;
}

#resourceAllocFilterDiv .card-body .btn{
    font-size: 12px !important;
}

.sidebar-footer{
    padding: 0px !important;
    margin: 30px;
}

//modals
.modal.show{
    font-size: 14px !important;
}

.modal-header-config{
    margin: auto;
    font-size: 28px;
}

.custom-modal-size .modal-dialog {
    max-width: 900px;
}
//end modals


.rti--input{
    width: 100% !important;
}

.rti--tag{
    margin-right: 0% !important;
}

.skill-header-row-1{
    flex: none !important;
    width: 0% !important;
}

.color-black{
    color: black !important;
}
//icons

// loader
.dot-spin {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow: 0 -18px 0 0 #9880ff, 12.727926px -12.727926px 0 0 #9880ff, 18px 0 0 0 #9880ff, 12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
    animation: dot-spin 1.5s infinite linear;
  }

  @keyframes dot-spin {
    0%, 100% {
      box-shadow: 0 -18px 0 0 #9880ff, 12.727926px -12.727926px 0 0 #9880ff, 18px 0 0 0 #9880ff, 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    12.5% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 0 #9880ff, 18px 0 0 0 #9880ff, 12.727926px 12.727926px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    25% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff, 12.727926px 12.727926px 0 0 #9880ff, 0 18px 0 0 #9880ff, -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    37.5% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 0 #9880ff, 0 18px 0 0 #9880ff, -12.727926px 12.727926px 0 0 #9880ff, -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    50% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff, -12.727926px 12.727926px 0 0 #9880ff, -18px 0 0 0 #9880ff, -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    62.5% {
      box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #9880ff, -18px 0 0 0 #9880ff, -12.727926px -12.727926px 0 0 #9880ff;
    }
    75% {
      box-shadow: 0 -18px 0 0 #9880ff, 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff, -12.727926px -12.727926px 0 0 #9880ff;
    }
    87.5% {
      box-shadow: 0 -18px 0 0 #9880ff, 12.727926px -12.727926px 0 0 #9880ff, 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 0 #9880ff;
    }
  }
// loader

.employeeSuggestions {
    max-height: 100px;
    border: 1px solid #ccc;
    overflow-y: auto;
    background-color: white;
}

.employeeSuggestion {
    cursor: pointer;
    padding: 5px;
    position: relative;
}

.employeeSuggestion:hover {
    background-color: #ccc;
}

//Login
.login-container {
    background-color: #fff;
    padding: 24px;
    width: 80%;
    max-width: 400px;
    height: 400px; /* Fixed height */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.login-container-right-side{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    width: 60%;
    max-width: 300px;
    justify-content: center; /* Center content horizontally */
    height: 400px; /* Fixed height */
    position: relative;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .login-container {
        width: 90%; /* Adjust width for smaller screens */
        max-width: 400px; /* Remove max-width */
        height: auto; /* Allow height to be determined by content */
        border-radius: 10px !important;
    }
    .login-container-right-side {
        position: relative;
        width: 80%; /* Adjust width for smaller screens */
        max-width: 400px; /* Remove max-width */
        border-radius: 0 0 10px 10px !important;
        height: auto;
    }

    .right-span{
        visibility: hidden;
    }
}

.login-btn-container {
  padding-top:50px;
  padding-bottom:30px;
}


.welcome-container {
  margin-top:40px;
}

.login-body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;

}

.login-container .login-button {
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #C8C8C8;
    font-size: 13px;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 200px;
    height: 40px !important;
    line-height: 30px;
    letter-spacing: 1px;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);

}
.login-container .login-button img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-top:-3px;
}
.login-container .login-button:hover {
    background-color: #005a9e;
    color: white;
}

.right-span {
    // display: inline-block;
    width: 100%;
    text-align: right;
    color: white;
    position: absolute;
    font-size: 12px;
    bottom: 20px;
    right: 20px;
}

.hover-card:hover {
    background-color: lightgray !important;
  }
.hover-card.active {
    background-color: lightgray;
}

.list-group-item-hover:hover {
    background-color: lightgray !important;
}
.list-group-item-hover.active {
    background-color: lightgray !important;
    border-color: lightgray !important;
    color: black !important;
}

.align-left {
    text-align: left;
}
.align-right {
    text-align: right;
    letter-spacing: 1px;
}

.essilor-logo {
  max-width: 200px;
  margin:0 auto;
}

.custom-table th {
    font-weight: normal;
}

.vertical-tower-header::before{
    font-family: "Jost", sans-serif;

    content: "Vertical Tower";
    background-color: #E5E4E2;
    color: black;
    border-radius: 10px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size:11px;
    margin-left:34px;
    margin-right:5px;
}

.service-line-header::before{
    font-family: "Jost", sans-serif;

    content: "Service Line";
    background-color: #E5E4E2;
    color: black;
    border-radius: 10px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: .1em;
    padding-bottom: .15em;
    font-size:11px;
    margin-left:42px;
    margin-right:5px;
}

.dropdown-toggle::after {
    font-size:18px;
}

.hooman-logo {
  max-width: 90%;
  margin-top: 4px;
  margin-left: 10px;
}

.float-start {
  float:left !important;
}

.float-start {
  float:right !important;
}

.error-container {
  font-size: 12px;
  color: red;
  word-wrap: break-word;
  text-align: center;
  height: 30px;
  margin-top:30px;
}

.center-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

// -------- react-select -------
.custom-option {
    transition: background 10ms;
}
.custom-option:first-child { // so that the first option "- Select [item] -" never has any hover effect
    &:hover {
        transition: none;
        background: inherit;
    }
}
.custom-option:hover {
    transition-delay: 10ms;
    background: #deebff;
}
.custom-option.custom-select__option--is-focused {
    background: none;
}
.custom-option.custom-select__option--is-selected {
    background: #2684FF;
}
// -------- ------------ -------

// .employee-item.card-body{

//     // max-height: 330px;
//     overflow-y: auto;
//     // @media screen and (min-width: 1920px) and (min-height: 1080px) {
//     //     max-height: 600px;
//     // }
//     max-height: calc(100vh - 267px);
// }

.tab-content{

    overflow-y: auto;
    max-height: calc(100vh - 267px);
}


#uncontrolled-tab-example-tab-information{
    background-color: #00AFA9;
    width: 120px !important;
    color: white;
    font-weight: bold;
}
#uncontrolled-tab-example-tab-skills{
    background-color: #336FF1;
    width: 120px !important;
    color: white;
    font-weight: bold;
}
#uncontrolled-tab-example-tab-members{
    background-color: #336FF1;
    width: 120px !important;
    color: white;
    font-weight: bold;
}
#uncontrolled-tab-example-tab-trainings{
    background-color: #79D65D;
    width: 120px !important;
    color: white;
    font-weight: bold;
}
#uncontrolled-tab-example-tab-history{
    background-color: #79D65D;
    width: 120px !important;
    color: white;
    font-weight: bold;
}
#uncontrolled-tab-example-tab-certifications{
    background-color: #EE8F14;
    width: 120px !important;
    color: white;
    font-weight: bold;
}

.nav-link.active{
    background-color: white !important;
    color: black !important;
}

.nav-item{    
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.min-vh-100{
    min-height: 100vh !important;
}
.min-vh-80{
    min-height: 80vh !important;
}

.center-align {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.vertical-align {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

ul.empMockClass li.nav-item{
    margin-bottom: 3px !important;
}

#emp_info_card{
    font-size: 12px;
    border-top-left-radius: 0px;
    .info-input{
        font-size: 12px !important;
    }
    .info-label{
        font-weight: 500;
    }
}
#empl_skills_card{
    font-size: 12px;
    border-top-left-radius: 0px;
    .info-input{
        font-size: 12px !important;
    }
    .info-label{
        font-weight: 500;
    }
}

.btn.btn-primary:disabled{
    background-color: rgb(90, 125, 208);
    border-color: rgb(90, 125, 208);
    // opacity: 1.0;
}

.react-datepicker__input-container {
    width: inherit;
}

.react-datepicker-wrapper {
    width: 100%;
}

.alignright {
    float: right; /* Align the button to the right */
  }

  .add-skill-top-margin-10{
    margin-top: 10px;
  }


  .employee-skill-tab th:nth-child(5) input {
    display: none;
  }

  button, a, .clickable {
    cursor: pointer !important;
  }
